<template>
    <a href="/">
        <svg class="logo" xmlns="http://www.w3.org/2000/svg" width="113" height="91" viewBox="0 0 113 91" fill="none">
            <path d="M104.409 9.05502C93.593 -2.91928 80.2668 0.364219 72.0586 6.64106L56.125 20.6432L40.1915 6.64106C34.8803 1.81282 17.788 -2.9193 7.35873 9.05502C-3.07051 21.0293 1.08192 36.0938 7.35876 41.8878L52.7452 87.2743C52.7452 87.2743 54.5324 89.2057 56.1251 89.2057C57.5736 89.2057 59.5049 87.2743 59.5049 87.2743L104.409 41.8878C111.168 34.6453 115.224 21.0293 104.409 9.05502Z" stroke="#303030" stroke-width="2"/>
            <path d="M55.6421 21.1262L49.8481 27.403C49.8481 27.403 49.9446 28.9481 52.2622 31.2657C54.5798 33.5833 58.0563 32.3038 59.5048 31.7485M72.0585 19.1948L59.5048 31.7485M49.8481 54.4418C48.8824 55.0855 46.3717 55.7937 44.0541 53.4761C41.7364 51.1585 42.9274 48.6477 43.5712 47.6821L59.5048 31.7485M49.8481 54.4418C50.8137 53.798 71.0928 33.6799 71.0928 33.6799M49.8481 54.4418L46.4682 58.3044C45.5026 59.592 44.6335 62.1671 46.9511 64.0985C49.2687 66.0298 51.7794 65.386 53.2279 64.5813C54.4135 63.419 56.0176 62.3196 57.0906 61.2677M78.3353 40.4396C69.9044 48.7052 64.7208 53.7871 57.0906 61.2677M57.0906 61.2677C56.1249 62.3722 55.2558 65.6435 57.5734 67.9611C59.891 70.2787 62.8846 69.0878 64.3331 68.444C66.834 65.9431 69.0313 63.7458 71.0928 61.6843M87.5092 45.2679C80.9593 51.8178 76.4918 56.2852 71.0928 61.6843M71.0928 61.6843C70.7709 62.9718 69.6443 65.6677 72.0585 67.4783C74.4726 69.2889 76.243 68.444 77.3697 68.444" stroke="#303030" stroke-width="2" stroke-linecap="round"/>
            <path d="M39.2256 18.2292L56.1248 35.1285" stroke="#303030" stroke-width="2" stroke-linecap="round"/>
            <path d="M39.7085 32.2314L49.3652 41.8881" stroke="#303030" stroke-width="2" stroke-linecap="round"/>
            <path d="M32.4663 38.9912L42.6058 48.6479" stroke="#303030" stroke-width="2" stroke-linecap="round"/>
            <path d="M23.2925 43.8193L46.4686 66.0297C46.9514 66.5126 48.3999 67.3817 50.3312 66.9954C52.2626 66.6091 55.8034 62.8108 56.1252 62.1671" stroke="#303030" stroke-width="2" stroke-linecap="round"/>
            <path d="M38.2602 58.3044C39.2258 59.9139 40.1915 63.1328 38.2602 65.0641C36.3288 66.9955 33.1099 66.6736 31.5005 66.0298" stroke="#303030" stroke-width="2" stroke-linecap="round"/>
            </svg>
    </a>
</template>

<script>
export default {
    name: 'HeaderComponentLogo',
}
</script>

<style lang="scss">

.logo {
    cursor: pointer;
    transition: all 0.4s ease-out;
    @media (max-width: 900px) {
        height: 70px;
    }
}
.logo path {
    stroke: $color-background;
}
.logo:hover path{
    stroke: $color-text;
}
</style>